var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-navbar',{staticStyle:{"background-color":"white","border":"solid 1px gray","box-shadow":"2px 2px 5px #999"},attrs:{"text":"white"}},[_c('b-navbar-brand',{attrs:{"href":"https://www.alianzadiagnostica.com"}},[_c('b-img',{attrs:{"src":"https://www.alianzadiagnostica.com/images/logo-img.png","alt":""}})],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.tokenAuth === null),expression:"tokenAuth === null"}]},[_c('b-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/admin"}},[_vm._v(" IPS")])],1),_c('b-nav-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.tokenAuth !== null && _vm.grupoFamiliar.length > 0),expression:"tokenAuth !== null && grupoFamiliar.length > 0"}]},[_c('b-button',{staticStyle:{"background-color":"#00b350"},attrs:{"variant":"success","size":"lg"},on:{"click":function($event){return _vm.closeSesion()}}},[_vm._v("Salir")])],1)],1)],1),_c('b-container',{staticClass:"mt-2",attrs:{"fluid":"lg"}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('router-view')],1)],1),(
        _vm.$route.name != 'Home' &&
          _vm.$route.name != 'Login' &&
          _vm.$route.name != 'Instructivo' &&
          _vm.$route.name != 'Admin' && 
          _vm.$route.name != 'Cancelaciones'
      )?_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('b-button',{staticClass:"btn-menu-principal",attrs:{"to":"/","variant":"dark"}},[_c('b-icon',{staticClass:"icon-home mr-3",attrs:{"icon":"house-fill"}}),_vm._v("Menú Principal")],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }