import axios from "axios";
import store from "../store/index";
import moment from 'moment'
import {asignarCitaMamografia, getAgendaMamografia} from "../interfazSolin/Mamografia";
import {asignarCitaOrtodoncia, getAgendaOrtodoncia} from "../interfazSolin/Ortodoncia";
import {asignarCitaEcografia, getAgendaEcografia} from "../interfazSolin/Ecografia";
import {asignarCitaRadiografia, getAgendaRadiografia} from "../interfazSolin/Radiografia";


// Consultar metodo para traer las especialidades segun lo que tenga en interfaz
//Se traen especialidades según los cups que tengan en el WebService de Interfaz
const getEspecialidades = (data) => {
  let urlApiInterfaz = store.state.urlProxyApi + '/interfaz-st';
  return new Promise((resolve, reject) => {
    axios
      .post(`${urlApiInterfaz}/consultar`, data)
      .then((response) => {
        let cupsMamografia = ['']
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Obtener información personal del afiliado del WebService de Interfaz
const getInfoAfiliadoInterfaz = (data) => {  
  let urlApiInterfaz = store.state.urlProxyApi + '/interfaz-st';
  return new Promise((resolve, reject) => {
    axios
      .post(`${urlApiInterfaz}/consultar`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//Se consulta la agenda disponible para la correspondiente especialidad
const getAgendaDisponible = async (data) => {
  console.log('getagenda',data)
  switch (data.especialidadCita) {
    case 'MAMO': //Mamografias
      return getAgendaMamografia(data);
      break;
    case 'ORT': //Odontologia Ortodoncia
      return getAgendaOrtodoncia(data);
      break;
    case 'ECO': //Ecografia
      return getAgendaEcografia(data);
      break;
    case 'RYX': //Ecografia
    console.log('get agenda',data)
      return getAgendaRadiografia(data);
      break;
    default:
      break;
  }
};

//Se asigna la cita para la especialidad indicada
const asignarCita = (data) => {
  switch (data.codespecialidad) {
    case 'MAMO': //Mamografias
      return asignarCitaMamografia(data);
      break;
    case 'ORT': //Ortodoncia
      return asignarCitaOrtodoncia(data);
      break;
    case 'ECO': //Ecografia
      return asignarCitaEcografia(data);
      break;
    case 'RYX': //Radiografias
      return asignarCitaRadiografia(data);
      break;
    default:
      break;
  }
};
//dejar para mañana
//Obtener listado de citas vigentes del afiliado
const citasVigentes = (data) => {
  // let urlApiMamografias = store.state.urlProxyApi + '/imagenologia/mamografia/';
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${store.state.urlProxyApi}/agenda-dx/listar-citas-afiliado`, {
        numero_documento: store.state.afiliado.identificacion,
        tipo_documento: store.state.afiliado.identificacionId
      })
      .then((response) => {
        if (response.data.length > 0) {
          let rta = response.data.map(e => {
            return {
              Error: "",
              CodEspecialidad: e.Especialidad,
              NombreEspecialidad: e.NomPrograma,
              fecha: e.Fecha,
              hora: e.Hora,
              duracion: e.TiempoAt,
              nombreips: e.NomSede,
              medico: e.CodMedico,
              nombremedico: e.NomMedico,
              direccionips: e.DirSede,
              origen: 'DX',
              nombreAfiliado: e.NombreAfil
            }
          })
          resolve(rta);
        }
        // if (response.data[0].Error === null || response.data[0].Error === "") {
        //   resolve(response.data);
        // } else if (response.data[0].Error === "Sin Datos") {
        //   resolve([]);
        // }
        resolve([]);
      })
      .catch((error) => {
        reject("Error al listar citas para el afiliado.");
      });
  });
};


//Cancelar cita de la especialidad indicada
const cancelaCita = (data) => {
  // 
  return new Promise(async (resolve, reject) => {
    console.log('cancelando',data)
    let dataToSend = {
      dataCita: {
        IdentificaTD: data.numero_documento + data.tipo_documento,
        Medico: data.medico,
        FechaReg: data.fecha,
        HoraReg: data.hora,
        CodEspecialidad: data.codEspecialidad   //Codigo de especialidad
      },
      dataToReport: {
        tipoDoc: data.tipo_documento,
        documento: data.numero_documento,
        especialidad: data.especialidad,  //Nombre especialidad
        sede: data.sede,
        linkCancelacion: store.state.cancelFromLinkMessage
      },
    };
    axios
      .post(`${store.state.urlProxyApi}/agenda-dx/cancelar-cita-afiliado`, dataToSend)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        console.log('error', error)
      });
  });
};

export {
  getEspecialidades,
  getAgendaDisponible,
  asignarCita,
  citasVigentes,
  cancelaCita,
  getInfoAfiliadoInterfaz
};
