import axios from "axios";
import store from "../store/index";
import moment from 'moment'

const getAgendaEcografia = (data) => {
    let urlApiEcografia = store.state.urlProxyApi + '/imagenologia/ecografia';  
    return new Promise((resolve, reject) => {
      console.log('data',data)
        let formatFechaCita = moment(data.fechaCita).format('YYYYMMDD')
        axios    
        .get(`${urlApiEcografia}/agenda/citas-disponibles/${formatFechaCita}`,{
          params: {
            reqPreparacion: data.reqPreparacion,
            codPrograma: data.codPrograma
          }
        })
        .then((response) => {                 
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

const asignarCitaEcografia = (data) => {
    let urlApiEcografia = store.state.urlProxyApi + '/imagenologia/ecografia';
    return new Promise((resolve, reject) => {
      axios
        .post(`${urlApiEcografia}/agenda/asignar-cita`, {
            ...data, 
            dataToReport: {
            tipoDoc: data.tipo_documento,
            documento: data.numero_documento,
            especialidad: data.especialidad,
            sede: data.sede
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

export {
    getAgendaEcografia,
    asignarCitaEcografia
};
