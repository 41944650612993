import axios from "axios";
// let urlApi = "https://pruebas.saludtotal.com.co/ApiAgenda/api";
// let urlApi = "https://transaccional.saludtotal.com.co/StApiAgenda/api";
import store from "../store/index";

const getToken = () => {
  return new Promise((resolve, reject) => {
    if (store.state.tokenAgenda !== null || store.state.tokenAgenda !== "") {
      resolve({
        Token: store.state.tokenAgenda,
      });
    }
    let dataToSend = {
      Token: store.state.tokenAuth,
      aporigen: "CITAS-ALIANZA",
    };
    axios
      .post(`${store.state.urlProxyApi}/agenda/getToken`, dataToSend)
      .then((response) => {
        store.commit("setTokenAgenda", response.data.Token);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    // axios
    //   .post(
    //     `${urlApi}/GetToken?Token=${dataToSend.Token}&aporigen=${dataToSend.aporigen}`
    //   )
    //   .then((response) => {
    //     store.commit("setTokenAgenda", response.data.Token);
    //     // console.log("token agenda");
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     // console.log("fallo token", error);
    //     reject(error);
    //   });
  });
};

const getEspecialidades = (data) => {
  return new Promise((resolve, reject) => {
    let dataToSend = {
      numero_documento: data.identificacion,
      tipo_documento: data.identificacionId,
    };
    axios
      .post(
        `${store.state.urlProxyApi}/agenda/listar-especialidades`,
        dataToSend,
        {
          headers: {
            Authorization: store.state.tokenAgenda,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log('Error consultando especialidades ST', error.response.data)
        resolve([]);
      });
    // Traemos token de agenda
    // getToken(data.authToken)
    //   .then((response) => {
    //     return axios.get(
    //       `${urlApi}/especialidades?numero_documento=${dataToSend.numero_documento}&tipo_documento=${dataToSend.tipo_documento}`,
    //       {
    //         headers: {
    //           Authorization: store.state.tokenAgenda,
    //         },
    //       }
    //     );
    //   })
    //   .then((response) => {
    //     resolve(response);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  });
};

const getEspecialidadesJson = (data) => {
  return new Promise((resolve, reject) => {
    let dataToSend = {
      numero_documento: data.identificacion,
      tipo_documento: data.identificacionId,
    };
    axios
      .post(
        `${store.state.urlProxyApi}/agenda/listar-especialidades-json`,
        dataToSend,
        {
          headers: {
            Authorization: store.state.tokenAgenda,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log('Error consultando especialidades ST', error.response.data)
        resolve([]);
      });
  });
};

const getAgendaDisponible = (data) => {
  return new Promise((resolve, reject) => {
    let dataToSend = {
      documentoafiliado: data.identificacion,
      tipo_documento: data.identificacionId,
      codespecialidad: data.especialidadCita,
      producto: 1,
      citasxmedico: 20,
      Fecha: data.fechaCita,
      horai: "null",
      mini: "null",
      horaf: "null",
      minf: "null",
      sede: "null",
      medico: "null",
      diassemana: "1,2,3,4,5,6,7",
      Oportunidad: true,
    };
    axios
      .post(`${store.state.urlProxyApi}/agenda/citas-disponibles`, dataToSend, {
        headers: {
          Authorization: store.state.tokenAgenda,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    // axios
    //   .get(`${urlApi}/CitasDisponiblesAfiliado`, {
    //     params: dataToSend,
    //     headers: {
    //       Authorization: store.state.tokenAgenda,
    //     },
    //   })
    //   .then((response) => {
    //     console.log("desde api", response);
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     reject({
    //       message: error.response.data,
    //       status: error.response.status,
    //     });
    //   });
  });
};

const asignarCita = (data) => {
  return new Promise((resolve, reject) => {
    let infoAfil = store.state.grupoFamiliar.find(
      (e) => e.BeneficiarioId == store.state.cita.afiliado.identificacion
    );
    let dataToSend = {
      dataCita: {
        numero_documento: data.numero_documento,
        tipo_documento: data.tipo_documento,
        idCita: data.idCita,
        planSalud: data.planSalud,
        sede: data.sede,
        duracion: data.duracion,
        fechaCita: data.fechaCita,
        codespecialidad: data.codespecialidad,
      },
      dataToReport: {
        tipoDoc: infoAfil.BeneficiarioTipoId,
        documento: infoAfil.BeneficiarioId,
        especialidad: store.state.cita.nomEspecialidad,
        origen: "WEB",
        sede: data.sede,
      },
    };
    // resolve();
    axios
      .post(`${store.state.urlProxyApi}/agenda/asignar-cita`, dataToSend, {
        headers: {
          Authorization: store.state.tokenAgenda,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    // axios
    //   .get(`${urlApi}/citasSolicita`, {
    //     params: dataToSend,
    //     headers: {
    //       Authorization: store.state.tokenAgenda,
    //     },
    //   })
    //   .then((response) => {
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  });
};

const citasVigentes = (data) => {
  return new Promise(async (resolve, reject) => {
    let dataToSend = {
      numero_documento: data.numero_documento,
      tipo_documento: data.tipo_documento,
      aplicativoOrigen: data.aplicativoOrigen,
    };
    let tmpTokenAgenda = null;
    if (store.state.tokenAgenda === null) {
      tmpTokenAgenda = await getToken(store.state.userLogged.token);
    } else {
      tmpTokenAgenda = store.state.tokenAgenda;
    }
    axios
      .post(
        `${store.state.urlProxyApi}/agenda/listar-citas-afiliado`,
        dataToSend,
        {
          headers: {
            Authorization: tmpTokenAgenda,
          },
        }
      )
      .then((response) => {
        if (response.data[0].Error === null || response.data[0].Error === "") {
          let listado = response.data.map(e => {
            return {
              ...e,
              origen: 'ST'
            }
          })
          resolve(listado);
        } else if (response.data[0].Error === "Sin Datos") {
          resolve([]);
        }
        reject(response.data.Error);
      })
      .catch((error) => {
        // reject("Error al listar citas para el afiliado.");
        console.log("Error al listar citas para el afiliado.")
        resolve([]);
      });
    // axios
    //   .get(`${urlApi}/CitasVigentes`, {
    //     params: dataToSend,
    //     headers: {
    //       Authorization: tmpTokenAgenda,
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data[0].Error === null || response.data[0].Error === "") {
    //       resolve(response.data);
    //     } else if (response.data[0].Error === "Sin Datos") {
    //       resolve([]);
    //     }
    //     reject(response.data.Error);
    //   });
  });
};

const cancelaCita = (data) => {
  console.log('cancela',data)
  return new Promise(async (resolve, reject) => {
    let infoAfil = store.state.grupoFamiliar.find(
      (e) => e.BeneficiarioId == data.numero_documento
    );
    let dataToSend = {
      dataCita: {
        numero_documento: data.numero_documento,
        tipo_documento: data.tipo_documento,
        aplicativoOrigen: data.aplicativoOrigen,
        idCita: data.idCita,
      },
      dataToReport: {
        tipoDoc: infoAfil.BeneficiarioTipoId,
        documento: infoAfil.BeneficiarioId,
        especialidad: data.especialidad,
        origen: "WEB",
        sede: data.sede,
        linkCancelacion: store.state.cancelFromLinkMessage
      },
    };
    let tmpTokenAgenda = null;
    if (store.state.tokenAgenda === null) {
      tmpTokenAgenda = await getToken(store.state.userLogged.token);
    } else {
      tmpTokenAgenda = store.state.tokenAgenda;
    }
    axios
      .post(`${store.state.urlProxyApi}/agenda/cancelar-cita`, dataToSend, {
        headers: {
          Authorization: store.state.tokenAgenda,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    // axios
    //   .get(`${urlApi}/CitasCancela`, {
    //     params: dataToSend,
    //     headers: {
    //       Authorization: tmpTokenAgenda,
    //     },
    //   })
    //   .then((response) => {
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  });
};

const getSedes = (data) => {
  return new Promise((resolve, reject) => {
    let dataToSend = {
      codciudad: data.codCiudad,
      especialidad: data.especialidad,
    };
    axios
      .post(
        `${store.state.urlProxyApi}/agenda/sedes-disponibles`,
        dataToSend,
        {
          headers: {
            Authorization: store.state.tokenAgenda,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log('Error consultando especialidades ST', error.response.data)
        resolve([]);
      });
  });
};

export {
  getEspecialidades,
  getEspecialidadesJson,
  getAgendaDisponible,
  asignarCita,
  citasVigentes,
  getToken,
  cancelaCita,
  getSedes
};
