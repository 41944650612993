
const namespaced = true;

const state = {

}

const actions = {
    login(){
        console.log('login salud total')
    }
}

module.exports = {
    state,
    actions,
    namespaced
}