import axios from "axios";
// let urlApi = "https://transaccional.saludtotal.com.co/ApiSeguridad/api";
import store from "../store/index";

const login = (data) => {
  return new Promise((resolve, reject) => {
    let dataToSend = {
      Aplicativo: "CITAS-ALIANZA",
      Clave: "Alianza2021.",
      DocumentoId: "915369141",
      EmpleadorId: "900038024",
      PerfilUsuario: "IPS",
      TipoEmpleadorId: "N",
      TipoDocumentoId: "C",
    };
    axios
      .post(`${store.state.urlProxyApi}/auth`, dataToSend)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    // axios
    //   .get(`${urlApi}/LogIn`, {
    //     params: dataToSend,
    //   })
    //   .then((response) => {
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  });
};

const loginApi = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${store.state.urlProxyApi}/saludtotal/auth/login`,null,{headers: {
        Authorization: 'alianzadx'
      }})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    // axios
    //   .get(`${urlApi}/LogIn`, {
    //     params: dataToSend,
    //   })
    //   .then((response) => {
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  });
};

const validateToken = (token) => {
  return new Promise((resolve, reject) => {
    let authToken = token;
    // resolve({
    //   Valido: true,
    //   Error: "",
    // });
    resolve({
      Valido: false,
      Error: "Token invalido",
    });
    // reject({
    //   Valido: false,
    //   Token: null,
    //   Error: "Error de de api",
    // });
    // let dataToSend = {
    //   token: "dfsjsdjkfnjaksfnjhdf",
    //   aplicativo: "CITAS-ALIANZA",
    // };
    // axios
    //   .get("https://pruebas.saludtotal.com.co/ApiSeguridad/api/ValidaToken", {
    //     params: dataToSend,
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     resolve(response.data);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
  });
};

export { login, validateToken, loginApi };
